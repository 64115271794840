const myMixin = {
  props: {
    animationSpeed: {
      type: String,
      default: () => "0.5s",
    },
    animationTimingFunction: {
      type: String,
      default: () => "linear",
    },
    animationDelay: {
      type: String,
      default: () => "0s",
    },
    threshold: {
      type: Number,
      default: () => 0.5,
    },
    rewinds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animate: false,
    };
  },
  watch: {},
  computed: {
    styles() {
      return {
        transition: `all ${this.animationSpeed} ${this.animationTimingFunction} ${this.animationDelay}`,
      };
    },
  },
  methods: {
    initAnimation(isShowing) {
      this.animate = this.rewinds ? isShowing : true;
    },
  },
};

export default myMixin;

<template>
  <div class="observer" ref="target">
    <slot></slot>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "AnimationObserver",
  props: {
    threshold: {
      type: Number,
      default: () => 0.5,
    },
  },
  watch: {
    animate(newVal) {
      this.$emit("showing", newVal);
    },
  },
  setup(props) {
    const target = ref();
    const animate = ref(false);
    const observer = new IntersectionObserver(
      ([entry]) => {
        animate.value = entry.isIntersecting;
      },
      {
        threshold: props.threshold,
      }
    );
    onMounted(() => {
      observer.observe(target.value);
    });
    return {
      animate,
      target,
    };
  },
};
</script>

<style lang="scss" scoped>
.observer {
}
</style>

<template>
  <AnimationObserver @showing="initAnimation" :threshold="threshold">
    <div :style="styles" :class="[{ animate }, 'animated-component']">
      <slot />
    </div>
  </AnimationObserver>
</template>

<script>
import AnimationMixin from "@/components/UI/Animations/AnimationMixin";
import AnimationObserver from "@/components/UI/Animations/AnimationObserver";

export default {
  name: "FadeIn",
  components: { AnimationObserver },
  mixins: [AnimationMixin],
};
</script>

<style lang="scss" scoped>
.animated-component {
  @apply relative;

  &:not(.animate) {
    @apply opacity-0;
  }

  .animate {
    @apply opacity-100;
  }
}
</style>

<template>
  <AnimationObserver @showing="showingInit" :threshold="threshold">
    <div
      :style="[styles, { transform: 'translateY(-' + currentPercent + '%)' }]"
      :class="[{ animate }, 'animated-component']"
    >
      <slot />
    </div>
  </AnimationObserver>
</template>

<script>
import AnimationMixin from "@/components/UI/Animations/AnimationMixin";
import AnimationObserver from "@/components/UI/Animations/AnimationObserver";

export default {
  name: "MoveUpOnScrollDown",
  components: { AnimationObserver },
  mixins: [AnimationMixin],
  props: {
    threshold: {
      type: Number,
      default: () => 0,
    },
    animationSpeed: {
      type: String,
      default: () => "0.15s",
    },
    travelPercentage: {
      type: Number,
      default: () => 25,
    },
    parentHeight: {
      type: Number,
      default: () => 0,
    },
    parentOffsetTop: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      currentPercent: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      this.handleScroll();
    }, 200);
  },
  methods: {
    showingInit(isShowing) {
      this.initAnimation(isShowing);
      this.updateTracker(isShowing);
    },
    updateTracker(isShowing) {
      if (!isShowing) {
        window.removeEventListener("scroll", this.handleScroll);
        return;
      }
      window.addEventListener("scroll", this.handleScroll);
    },
    handleScroll() {
      // const thisContainerHeight = this.$el.clientHeight;

      // if (thisContainerHeight <= this.parentHeight) {
      //   console.warn("MoveUpOnScrollDown: Not enough height to animate.");
      //   // return;
      // }

      const parentMinVisibility = this.parentOffsetTop;
      const parentMaxVisibility = this.parentOffsetTop + this.parentHeight;

      const scrollTopPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollBottomPosition = scrollTopPosition + window.innerHeight;

      if (
        scrollBottomPosition >= parentMinVisibility &&
        scrollTopPosition <= parentMaxVisibility
      ) {
        //Container is in view

        const percentOfAnimation =
          (parentMaxVisibility - scrollTopPosition) /
          (this.parentHeight + window.innerHeight);
        /* eslint-disable-next-line */
        this.currentPercent = this.travelPercentage - (this.travelPercentage * percentOfAnimation);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.animated-component {
  @apply relative;

  &:not(.animate) {
    @apply opacity-0;
  }

  &.animate {
    @apply opacity-100;
  }
}
</style>
